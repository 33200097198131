import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, autocompleteClasses, IconButton, styled, TextField } from '@mui/material';
import { useNextMonthDisabled, usePreviousMonthDisabled } from '@mui/x-date-pickers/internals';
import { PickersRangeCalendarHeaderProps } from '@mui/x-date-pickers-pro';
import { MuiExpandMoreIcon, MuiNavigateBeforeIcon, MuiNavigateNextIcon } from '@nuix/nomi-icons';
import { Dayjs } from 'dayjs';
import { getMonthOptions, getYearOptions } from '../../constants/Calendar';

const PREFIX = 'CalendarHeader';
const classes = {
    navigateButton: `${PREFIX}__navigate-button`,
};

const HeaderContainer = styled('div')({
    alignItems: 'center',
    columnGap: '10px',
    display: 'flex',
    padding: '19px 12px 8px 12px',
    [`.${autocompleteClasses.root}`]: {
        width: '80px',
    },
    [`.${classes.navigateButton}`]: {
        width: '32px',
    },
});

interface OwnProps extends PickersRangeCalendarHeaderProps<Dayjs> {
    defaultMonth: Dayjs | null;
}

const CalendarHeader: FC<OwnProps> = ({
    currentMonth,
    defaultMonth,
    disableFuture,
    disablePast,
    maxDate,
    minDate,
    month,
    onMonthChange,
    timezone,
}) => {
    const { i18n, t } = useTranslation();

    useEffect(() => {
        if (defaultMonth) {
            const offset = defaultMonth.month() - month.month() + (defaultMonth.year() - month.year()) * 12;
            onMonthChange(currentMonth.add(offset, 'month'), offset > 0 ? 'left' : 'right');
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const monthOptions = useMemo(() => getMonthOptions(i18n), [i18n]);

    const selectedMonthOption = useMemo(
        () => monthOptions.find(option => option.value === month.month()),
        [month, monthOptions]
    );

    const handleMonthChanged = useCallback(
        (newMonth: number) => {
            const offset = newMonth - month.month();
            onMonthChange(currentMonth.add(offset, 'month'), offset > 0 ? 'left' : 'right');
        },
        [currentMonth, month, onMonthChange]
    );

    const yearOptions = useMemo(() => getYearOptions(), []);

    const handleYearChanged = useCallback(
        (newYear: number) => {
            const offset = newYear - month.year();
            onMonthChange(currentMonth.add(offset, 'year'), offset > 0 ? 'left' : 'right');
        },
        [currentMonth, month, onMonthChange]
    );

    const selectNextMonth = () => onMonthChange(currentMonth.add(1, 'month'), 'left');
    const selectPreviousMonth = () => onMonthChange(currentMonth.subtract(1, 'month'), 'right');

    const isNextMonthDisabled = useNextMonthDisabled(currentMonth, {
        disableFuture,
        maxDate,
        timezone,
    });

    const isPreviousMonthDisabled = usePreviousMonthDisabled(currentMonth, {
        disablePast,
        minDate,
        timezone,
    });

    return (
        <>
            <HeaderContainer data-testid='calendar-header'>
                <div className={classes.navigateButton}>
                    <IconButton
                        onClick={selectPreviousMonth}
                        title={t('calendar-header.button.previous-month')}
                        disabled={isPreviousMonthDisabled}
                    >
                        <MuiNavigateBeforeIcon />
                    </IconButton>
                </div>
                <Autocomplete
                    disableClearable
                    getOptionLabel={option => option.label}
                    filterOptions={(options, state) => {
                        const inputValue = state.inputValue.trim().toLowerCase();
                        return options.filter(
                            option => !inputValue.length || option.label.toLowerCase().includes(inputValue)
                        );
                    }}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    onChange={(_, val) => handleMonthChanged(val?.value)}
                    options={monthOptions}
                    popupIcon={<MuiExpandMoreIcon />}
                    renderInput={params => (
                        <TextField
                            {...params}
                            inputProps={{
                                ...params.inputProps,
                                'aria-label': t('calendar-header.aria-label.month'),
                            }}
                        />
                    )}
                    value={selectedMonthOption}
                />
                <Autocomplete
                    disableClearable
                    getOptionLabel={option => option.toString()}
                    filterOptions={(options, state) => {
                        const inputValue = state.inputValue.trim();
                        return options.filter(option => !inputValue.length || option.toString().includes(inputValue));
                    }}
                    onChange={(_, val) => handleYearChanged(val)}
                    options={yearOptions}
                    popupIcon={<MuiExpandMoreIcon />}
                    renderInput={params => (
                        <TextField
                            {...params}
                            inputProps={{
                                ...params.inputProps,
                                'aria-label': t('calendar-header.aria-label.year'),
                            }}
                        />
                    )}
                    value={month.year()}
                />
                <div className={classes.navigateButton}>
                    <IconButton
                        onClick={selectNextMonth}
                        title={t('calendar-header.button.next-month')}
                        disabled={isNextMonthDisabled}
                    >
                        <MuiNavigateNextIcon />
                    </IconButton>
                </div>
            </HeaderContainer>
        </>
    );
};

export default CalendarHeader;
